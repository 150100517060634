import * as React from "react";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const OurPrincipalsGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {[...gridItems].reverse().map((item) => (
      <div key={item.name} className="column is-6">
        <section className="section">
          <div className="has-text-left">
            <div
              style={{
                width: "140px",
                display: "inline-block",
              }}
            >
              {item.image ? <PreviewCompatibleImage imageInfo={{image: item.image, alt: item.name}} /> : null }
            </div>
          </div>
          <p>
            <strong>{item.name}</strong><br />
            <em>(from {item.serviceStartDate} to {item.serviceEndDate})</em><br />
            {item.summary}</p>
        </section>
      </div>
    ))}
  </div>
);

export default OurPrincipalsGrid;
