import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import OurPrincipalsGrid from "../components/OurPrincipalsGrid";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const OurPrincipalsPageTemplate = ({
  image,
  title,
  description,
  principals,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <p>{description}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <OurPrincipalsGrid gridItems={principals} /> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const OurPrincipalsPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;

  return (
    <Layout 
      pageTitle={frontmatter.title}
      pageDescription={frontmatter.description}
      pageSlug={fields.slug}
    >
      <OurPrincipalsPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        description={frontmatter.description}
        principals={frontmatter.principals}
      />
    </Layout>
  );
};

export default OurPrincipalsPage;

export const OurPrincipalsPageQuery = graphql`
  query OurPrincipalsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        description
        principals {
          name
          image {
            childImageSharp {
              gatsbyImageData(width: 140, quality: 64, layout: CONSTRAINED)
            }
          }
          serviceStartDate
          serviceEndDate
          isCurrentPrincipal
          summary
        }
      }
    }
  }
`;
